import React, { useContext } from 'react'
import { TranslateContext } from '../context/translate'
import TermsOfServiceEn from './components/termsOfServiceEn'
const Page = (props) => {
    const tranlsate=useContext(TranslateContext);
    return (
        <div>
            <TermsOfServiceEn />
        </div>
    )
}
export default Page

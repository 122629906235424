import React from 'react'
import Logo from '@/components/Logo'
import config from '@/utils/config'
const Header=(props)=>{
    return <div className="header header-top top30 flex items-center absolute full-width left0 top0 pl60 pr60 sizing">
        <Logo />
        <div className="fz30 lh35 ml20 text-white">{config.appName}</div>
    </div>
}

export default Header;

import React from 'react'
import dataRule from './termOfServiceText';
import config from '@/utils/config'
const Page = (props) => {
    let logoName=config.logoName || 'logo'
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                <div className="logo">
                    <img src={require(`@/assets/images/${logoName}.png`).default} alt="" />
                </div>
                <div className="title fz40">Terms of Service</div>
            </div>
            <div className="f-line"></div>

            <div className="wrap fz24 lh38 mt40">
                {Object.keys(dataRule).map((key) => {
                    return (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: dataRule[key],
                            }}
                            key={key}
                        ></p>
                    );
                })}
            </div>
        </div>
    )
}
export default Page
